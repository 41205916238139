export function toggleContent() {
  const init = () => {
    function collapse(toggle) {
      let id = toggle.getAttribute('data-a11y-toggle');
      let collapsibleBox = document.getElementById(id);
      collapsibleBox.setAttribute('aria-hidden', true);
      toggle.setAttribute('aria-expanded', false);
    }

    function collapseAll(event) {
      let target = event.target;
      let container = target.closest('.js-toggle-content');
      let toggles = container.querySelectorAll('[data-a11y-toggle]');

      Array.prototype.slice
        .call(toggles)
        .filter(function (toggle) {
          return toggle !== target;
        })
        .forEach(collapse);
    }

    let toggles = Array.prototype.slice.call(
      document.querySelectorAll('.js-toggle-content [data-a11y-toggle]'),
    );

    toggles.forEach(function (toggle) {
      toggle.addEventListener('click', collapseAll);
    });
  };

  init();
}
