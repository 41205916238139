import Splide from '@splidejs/splide';

export default function renderSlider() {
  const splideList = [...document.querySelectorAll('.js-splide')];
  if (splideList.length) {
    splideList.forEach(slide => {
      const itemsInSlide = slide.querySelectorAll('li').length;

      if (itemsInSlide > 1) {
        let splide = new Splide(slide, {
          type: 'loop',
        }).mount();
      }
    });
  }
}
