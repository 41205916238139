import '../styles/main.scss';
import Headroom from 'headroom.js';
import 'a11y-toggle';
import LazyLoad from 'vanilla-lazyload';
import { lazyLoadSettings } from './modules/lazyload-settings';
import 'bootstrap/js/dist/collapse';
import menu from './modules/menu';
import filter from './modules/filter';
import { toggleButton } from './modules/toggle-button';
import mobileMenu from './modules/mobile-menu';
import renderSlider from './modules/testimonial-slider';
import { toggleContent } from './modules/toggleContent';
import contactForm from './modules/contactform';
import { youtubeEmbed } from './modules/youtube-embed';
import playPauseVideo from './modules/play-pause-video';
import { cookiebot } from './modules/cookiebot';
import loadCapcha from './modules/loadcaptcha';
import { breakingnews } from './modules/breakingnews';
import { smoothanchorscroll } from './modules/smoothanchorscroll';

import AOS from 'aos';

const header = document.querySelector('.js-header');

const getHeadroomOptions = () => {
  return { offset: 64, tolerance: 10 };
};

const start = () => {
  console.log('App loaded!');
  breakingnews.init();
  if (header) {
    const headroom = new Headroom(
      header,
      Object.assign({}, getHeadroomOptions()),
    );

    headroom.init();
  }

  const lazyLoad = new LazyLoad(lazyLoadSettings);
  document.querySelector('.menu-bar') ? menu() : null;
  document.querySelector('.splide') ? renderSlider() : null;

  toggleButton();
  mobileMenu();
  filter();
  toggleContent();
  contactForm();
  youtubeEmbed();
  cookiebot.init();
  loadCapcha.init();
  smoothanchorscroll.init();

  const playPauseVideoEl = document.querySelector('.js-hero-video');
  if (playPauseVideoEl) {
    playPauseVideo();
  }

  AOS.init({
    once: true,
    duration: 600,
    offset: 400,
  });
};

export const app = () => {
  start();
};

// eslint-disable-next-line no-unused-expressions
document.readyState !== 'loading'
  ? app()
  : document.addEventListener('DOMContentLoaded', app);
