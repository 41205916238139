import Cookies from 'js-cookie';

export const breakingnews = {
  init() {
    const header = document.querySelector('.js-header');
    const breakingNews = document.querySelector('.js-breakingnews');
    if (
      document.body.classList.contains('has-breaking-news') &&
      !Cookies.get('bannerClosed') &&
      breakingNews
    ) {
      breakingNews.classList.remove('d-none');

      if (!document.body.classList.contains('has-hero')) {
        const top =
          breakingNews.getBoundingClientRect().height +
          header.getBoundingClientRect().height;
        document.body.style.paddingTop = top + 'px';
      }
      const closeBtn = document.querySelector('.js-breakingnews');
      if (closeBtn) {
        closeBtn.addEventListener('click', this.onCloseBtnClick);
      }
    }
  },
  onCloseBtnClick(e) {
    e.preventDefault();
    Cookies.set('bannerClosed', 'true', { path: '/' });
    const breakingNews = document.querySelector('.js-breakingnews');
    breakingNews.classList.add('d-none');
    document.body.setAttribute('style', '');
  },
};
