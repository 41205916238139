export const cookiebot = {
  init() {
    let changeCookieLinks = document.querySelectorAll(
      '.js-cookie-consent-change',
    );
    if (changeCookieLinks.length) {
      changeCookieLinks.forEach(link => {
        link.addEventListener('click', this.handleCookieConsentChangeClick);
      });
    }
  },

  handleCookieConsentChangeClick(e) {
    e.preventDefault();
    Cookiebot.renew();
  },
};
