const video = document.querySelector('.js-hero-video');
const mobileVideo = document.querySelector('.js-mobile-hero-video');
const play = document.querySelector('.hero__toggle-play');
const playLabel = play?.querySelector('.hero__toggle-play__label');
const playLabelText = play?.getAttribute('data-video-play');
const pauseLabelText = play?.getAttribute('data-video-pause');
const playDisabled = localStorage.getItem('play-disabled');
const LgViewPort = 992;
const togglePlay = () => {
  const isPaused =
    window.innerWidth >= LgViewPort || !mobileVideo
      ? video.paused
      : mobileVideo.paused;
  isPaused ? playVideo() : pauseVideo();
};

const playVideo = () => {
  if (window.innerWidth >= LgViewPort || !mobileVideo) {
    const playPromise = video.play();
  } else {
    const playMobilePromise = mobileVideo.play();
  }
  localStorage.removeItem('play-disabled');
  play.setAttribute('aria-pressed', 'true');
  playLabel.textContent = pauseLabelText;
};

const pauseVideo = () => {
  if (window.innerWidth >= LgViewPort || !mobileVideo) {
    const pausePromise = video.pause();
  } else {
    const pauseyMobilePromise = mobileVideo.pause();
  }
  localStorage.setItem('play-disabled', 'true');
  play.setAttribute('aria-pressed', 'false');
  playLabel.textContent = playLabelText;
};

export const init = () => {
  if (video) {
    playDisabled ? pauseVideo() : playVideo();
    play.classList.remove('invisible');
    play.addEventListener('click', togglePlay);
  }
};

export default init;
