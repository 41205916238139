export const smoothanchorscroll = {
  init() {
    const anchors = [...document.querySelectorAll('.js-anchor')];
    if (anchors.length) {
      anchors.forEach(a => {
        a.addEventListener('click', this.onAnchorClick);
      });
    }

    const anchorsWithoutClass = [...document.querySelectorAll('a[href*="#"]')];

    if (anchorsWithoutClass.length) {
      anchorsWithoutClass.forEach(a => {
        a.addEventListener('click', this.onAnchorClick);
      });
    }

    if (window.location.hash) {
      const scrollToElement = document.querySelector(window.location.hash);
      const header = document.querySelector('.js-header');

      if (scrollToElement) {
        const timeout = 1000;
        let headerOffset = 300;
        headerOffset = header ? header.offsetHeight : headerOffset;
        setTimeout(function (e) {
          window.scrollTo(0, scrollToElement.offsetTop - headerOffset);
        }, timeout);
      }
    }
  },
  onAnchorClick(e) {
    let anchor = e.currentTarget;
    const href = anchor.getAttribute('href');
    const id = href.substring(href.lastIndexOf('#'));
    const target = document.querySelector(id);
    if (target) {
      let yOffset = -150;
      let y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;
      let timeout = 100;
      setTimeout(function (e) {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, timeout);
    }
  },
};
