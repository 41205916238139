let players = [];
const jsYouTubeEmbedCls = '.js-video';
const jsYouTubeEmbedPlayCls = '.js-video-play';
const embedResponsiveItemCls = '.js-aspect-ratio__item';

const onPlayerReady = event => {
  event.target.playVideo();
};

const loadVideo = event => {
  if (Cookiebot && Cookiebot.consent.marketing) {
    const container = event.currentTarget.closest(jsYouTubeEmbedCls);
    const id = container.dataset.videoId;
    const playIcon = container.querySelector(jsYouTubeEmbedPlayCls);
    const cover = container.querySelector(embedResponsiveItemCls);

    playIcon.setAttribute('class', playIcon.getAttribute('class') + ' d-none');
    cover.classList.add('d-none');

    const allPlayers = container.querySelectorAll('.js-videoplayer');

    allPlayers.forEach(el => {
      let player = new YT.Player(el, {
        height: '100%',
        width: '100%',
        videoId: id,
        events: {
          onReady: onPlayerReady,
        },
      });

      players.push(player);
    });
  }
};

const AddListener = videoContainer =>
  videoContainer && videoContainer.addEventListener('click', loadVideo);

export const youtubeEmbed = () => {
  const containers = [...document.querySelectorAll(jsYouTubeEmbedCls)];

  if (containers.length) {
    const firstScriptTag = document.getElementsByTagName('script')[0];
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    containers.forEach(function (el) {
      AddListener(el.querySelector(jsYouTubeEmbedPlayCls));
    });
  }
};
