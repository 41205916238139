function contactForm() {
  const isValidEmail = email => {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
  };

  const validateInput = input => {
    let isValid = false;
    if (
      input.classList.contains('name') ||
      input.classList.contains('message')
    ) {
      isValid = input.value.length > 0;
    } else if (input.classList.contains('email')) {
      const phone = document.querySelector('.js-validate.phone');
      isValid = isValidEmail(input.value) || phone.value.length > 0;
    } else if (input.classList.contains('phone')) {
      const email = document.querySelector('.js-validate.email');
      isValid = isValidEmail(email.value) || input.value.length > 0;
    } else if (input.classList.contains('help')) {
      isValid = input.querySelector('input:checked') !== null;
    } else if (input.classList.contains('terms')) {
      isValid = input.checked;
    }

    const container = input.closest('.js-form__container');
    if (isValid) {
      container.classList.remove('form__container--invalid');
    } else {
      container.classList.add('form__container--invalid');
    }
  };

  const onContactFormSubmit = e => {
    const form = e.target;
    e.preventDefault();

    const errorMessage = document.querySelector('.js-error-message');
    const mailSendErrorMessage = document.querySelector('.js-mail-senderror');

    errorMessage.classList.add('d-none');
    mailSendErrorMessage.classList.add('d-none');

    const validationFields = [...form.querySelectorAll('.js-validate')];
    validationFields.forEach(field => {
      validateInput(field);
    });

    let isValid = form.querySelector('.form__container--invalid') === null;

    if (isValid) {
      fetch(form.action, {
        method: form.method,
        body: new FormData(form),
      })
        .then(res => res.json())
        .then(data => {
          if (data.recaptchaError) {
            errorMessage.classList.remove('d-none');
          } else if (data.success) {
            form.classList.add('d-none');
            const thankYouMsg = document.querySelector('.js-thankyou');
            const thankYouPageInput =
              document.querySelector('.js-thankyoupage');

            if (thankYouPageInput && thankYouPageInput.value) {
              window.location.href = thankYouPageInput.value;
            } else {
              thankYouMsg.classList.remove('d-none');
            }
          } else {
            mailSendErrorMessage.classList.remove('d-none');
          }
        });
    }
  };

  const init = () => {
    const form = document.querySelector('.js-contact-form');
    const formInputs = [...document.querySelectorAll('.js-validate')];
    if (form) {
      form.addEventListener('submit', onContactFormSubmit);
      formInputs.forEach(input => {
        input.addEventListener('change', function (e) {
          validateInput(e.currentTarget);
        });
      });
    }
  };

  init();
}

export default contactForm;
