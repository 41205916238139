let isCaptchaSet = false;

const timerFunction = (hiddenField, siteKey) => {
  const sixty = 60;
  const thousand = 1000;
  window.grecaptcha
    .execute(siteKey, { action: 'SendContactFormMail' })
    .then(function (token) {
      // Enable the submit button now we have a token.
      hiddenField.form
        .querySelector('[type=submit]')
        .removeAttribute('disabled');
      hiddenField.value = token;
    });
  setTimeout(timerFunction, sixty * thousand);
};

const initReCaptcha = () => {
  Array.from(document.getElementsByClassName('js-recaptcha-v3-config')).forEach(
    function (configElement) {
      // Get reference to hidden field.
      let hiddenField = document.getElementById(configElement.dataset.id);
      let siteKey = configElement.dataset.siteKey;

      // Disable the submit button for this form, until we actually have a key from Google reCAPTCHA.
      hiddenField.form
        .querySelector('[type=submit]')
        .setAttribute('disabled', 'disabled');

      window.grecaptcha.ready(function () {
        timerFunction(hiddenField, siteKey);
      });
    },
  );
};

const onInputFocus = e => {
  if (!isCaptchaSet) {
    const timeout = 200;
    const captchaHiddenInput = document.querySelector('.js-recaptchasitekey');
    createScriptTag(captchaHiddenInput.value);
    setTimeout(function (e) {
      initReCaptcha();
    }, timeout);
    isCaptchaSet = true;
  }
};

const createScriptTag = api => {
  let tag = document.createElement('script');
  tag.src = api;
  tag.dataset.cookieconsent = 'ignore';
  let firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

const init = () => {
  const captchaHiddenInput = document.querySelector('.js-recaptchasitekey');
  if (captchaHiddenInput) {
    const formInputs = [...document.querySelectorAll('.js-contact-form input')];
    formInputs.forEach(input => {
      input.addEventListener('focus', onInputFocus);
    });
  }
};

const loadCapcha = {
  init,
};

export default loadCapcha;
