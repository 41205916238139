import { defaultParameters } from '../lib/utils';
import LazyLoad from 'vanilla-lazyload';
import { lazyLoadSettings } from './lazyload-settings';

function filter() {
  const setCanonicalUrl = url => {
    const canonicalUrl = document.querySelector('.js-canonicalurl');
    if (canonicalUrl) {
      canonicalUrl.href = url;
    }
  };

  const filterResults = (page, fromLoadMore) => {
    const selectedBtns = document.querySelectorAll('.js-filter-btn.selected');
    let selectedValues = '';
    let onlyConsulting = false;
    let onlyRecruit = false;
    if (selectedBtns.length) {
      selectedValues = selectedBtns[0].dataset.value.replace('&', '|');
      onlyConsulting = selectedBtns[0].dataset.isConsulting === 'True';
      onlyRecruit = selectedBtns[0].dataset.isRecruit === 'True';
    }
    let baseFilterUrl = `${window.location.pathname}`;
    const urlInput = document.querySelector('.js-filterurl');
    if (urlInput) baseFilterUrl = urlInput.value;

    let url = `${baseFilterUrl}?p=${page}&filters=${selectedValues}&fromLoadMore=${fromLoadMore}`;

    if (onlyConsulting) url += '&onlyConsulting=true';

    if (onlyRecruit) url += '&onlyRecruit=true';

    fetch(url, defaultParameters)
      .then(response => response.json())
      .then(data => {
        const loadMoreBtn = document.querySelector('.js-filter-loadmore');
        if (data.view) {
          let historyUrl = `${window.location.origin}${window.location.pathname}?p=${page}`;

          if (selectedValues.length && selectedValues !== 'all') {
            historyUrl += `&filters=${selectedValues}`;
          }

          setCanonicalUrl(historyUrl, null, null);

          history.pushState({}, 'Filter success', historyUrl);

          const articleList = document.querySelector('.js-article-list');

          if (!data.fromLoadMore) {
            articleList.innerHTML = data.view;
          } else {
            articleList.insertAdjacentHTML('beforeend', data.view);
          }
          const lazyLoad = new LazyLoad(lazyLoadSettings);
        }

        if (data.resultsText) {
          const resultsText = document.querySelector('.js-resultstext');
          if (resultsText) resultsText.innerText = data.resultsText;
        }
        if (loadMoreBtn) {
          if (data.endOfList) {
            loadMoreBtn.classList.add('d-none');
          } else {
            loadMoreBtn.classList.remove('d-none');
          }
        }
      })
      .catch(function (err) {
        console.log('Fetch Error: ', err);
      });
  };

  const onFilterBtnClick = e => {
    const btns = [...document.querySelectorAll('[data-value]')];
    btns.forEach(btn => {
      btn.classList.remove('selected');
      btn.setAttribute('aria-selected', false);
    });

    let target = e.currentTarget;
    target.classList.toggle('selected');
    target.setAttribute('aria-selected', target.classList.contains('selected'));

    const selectedValues = target.dataset.value.replace('&', '|');

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('p', '1');
    urlParams.set('filters', selectedValues);

    history.pushState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`,
    );

    const loadMoreBtn = document.querySelector('.js-filter-loadmore');
    if (loadMoreBtn) {
      loadMoreBtn.dataset.page = '1';
    }

    filterResults(1, false);
  };

  const onLoadMoreBtnClick = e => {
    const target = e.currentTarget;
    let nextPage = parseInt(target.dataset.page) + 1;
    target.dataset.page = nextPage;

    filterResults(nextPage, true);
  };

  const init = () => {
    const filterBtns = document.querySelectorAll('.js-filter-btn');
    if (filterBtns.length) {
      filterBtns.forEach(btn => {
        btn.addEventListener('click', onFilterBtnClick);
      });
    }

    const loadMoreBtn = document.querySelector('.js-filter-loadmore');
    if (loadMoreBtn) {
      loadMoreBtn.addEventListener('click', onLoadMoreBtnClick);
    }

    if (filterBtns || loadMoreBtn) {
      setCanonicalUrl(window.location.href);
    }
  };

  init();
}

export default filter;
