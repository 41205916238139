import { generateId, attrToggle } from '../lib/utils';

export default function mobileMenu(options) {
  options = options || {};

  const MOBILE_MENU_PREFIX = 'm-menu-';
  const mMenuClass = options.mMenu || '.js-m-menu';
  const mMenuToogleClass = '.js-m-menu__toggle';

  const mMenu = document.querySelector(mMenuClass);
  const toggles = mMenu ? mMenu.querySelectorAll(mMenuToogleClass) : null;

  const init = () => {
    if (toggles == null) return;

    [...toggles].forEach(el => {
      const target = el.classList.contains('m-menu__toggle--reverse')
        ? el.closest('.js-m-menu-secondlevel')
        : el.nextElementSibling;

      if (target) {
        const id = generateId(el, MOBILE_MENU_PREFIX);

        el.setAttribute('id', id);
        el.setAttribute('aria-controls', id);
        if (el.classList.contains('selected')) {
          el.setAttribute('aria-expanded', true);
        } else {
          el.setAttribute('aria-expanded', false);
        }
        el.setAttribute('aria-haspopup', true);
        target.setAttribute('aria-labelledby', id);
        if (!target.classList.contains('selected')) {
          target.setAttribute('aria-hidden', true);
        } else {
          target.setAttribute('aria-hidden', false);
        }

        el.addEventListener('click', handleToggle);
      }
    });
  };

  const handleToggle = event => {
    const currentElement = event.currentTarget;
    const targetElement = currentElement.classList.contains(
      'm-menu__toggle--reverse',
    )
      ? currentElement.closest('.js-m-menu-secondlevel')
      : currentElement.nextElementSibling;
    const x = currentElement.closest('.m-menu__item--has-children');

    attrToggle(currentElement, 'aria-expanded');
    attrToggle(targetElement, 'aria-hidden');

    const isOpen =
      currentElement.getAttribute('aria-expanded') === 'true' ? true : false;
  };

  if (mobileMenu) {
    init();
  }
}
